<template>
  <v-container
    ><v-row>
      <v-card>
        <v-card-text>
          <v-tabs v-model="tabs">
            <v-tab href="#tab-1">Impressum</v-tab>
            <v-tab href="#tab-2">Imprint</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs">
            <v-tab-item value="tab-1"><imprint-de /></v-tab-item>
            <v-tab-item value="tab-2"><imprint-en /></v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card> </v-row
  ></v-container>
</template>
<script>
import ImprintDe from '@/main/legal/imprint/ImprintDe'
import ImprintEn from '@/main/legal/imprint/ImprintEn'
export default {
  components: { ImprintEn, ImprintDe },
  data() {
    return {
      tabs: null
    }
  }
}
</script>
